import { NextApiRequest } from 'next'

import { GeoAddress } from '@bff/models/bff/geolocality'
import { ErrorResponse } from '@bff/models/og/menu'
import { FooterWarningMessages, HealthWarningMessages } from '@bff/models/og/rules'
import { GEOLOCALITY_API_URL } from '@bff/utils/constants'

export default async function postGeolocalityAddress(
  req: NextApiRequest
): Promise<{ status: number; data: GeoAddress | ErrorResponse }> {
  if (!req.body.address) return { status: 400, data: { message: 'Missing address' } }
  try {
    const body = JSON.stringify({ address: req.body.address })
    const response = await fetch(`${GEOLOCALITY_API_URL}/v1/addresses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    const result = await response.json()
    if (!response.ok) {
      return {
        status: response.status,
        data: { message: `${result.title} - ${result.message}` }
      }
    }
    return { status: response.status, data: result }
  } catch (err) {
    return { status: 500, data: { message: `Geolocality responded unexpectedly when creating a new address: ${err}` } }
  }
}

export async function getLegacyRules(
  req: NextApiRequest
): Promise<{ status: number; data: FooterWarningMessages | HealthWarningMessages | ErrorResponse }> {
  const query = {}
  for (const param in req.query) {
    const paramValue = req.query[param]
    query[param] = Array.isArray(paramValue) ? paramValue.join(',') : paramValue
  }
  const queryParams = new URLSearchParams(query)
  const URL = `${GEOLOCALITY_API_URL}/rules?${queryParams.toString()}`

  try {
    const response = await fetch(URL)
    const result = await response.json()
    if (!response.ok) {
      return {
        status: response.status,
        data: { message: `${result.title} - ${result.message}` }
      }
    }
    return { status: response.status, data: result }
  } catch (err) {
    return { status: 500, data: { message: `Geolocality responded unexpectedly when getting rules: ${err}` } }
  }
}

/**
 * Check if there is metadata for the address. If not, it is unsupported and out of our service area
 */
export function checkOutOfServiceArea(address: GeoAddress): boolean {
  const hasMetadata = Boolean(address.metadata.length)
  if (!hasMetadata) {
    console.info(`Setting address to ${address.id} failed because there are no service areas`)
    return true
  }
  return false
}

/**
 * Check if restricted to adult recreational use only
 */
export function checkRecreationalAdultUseOnly(address: GeoAddress): boolean {
  for (const metadata of address.metadata) {
    if ('adult_use' in metadata.properties && !metadata.properties.adult_use) {
      console.info(
        `Setting address to ${address.id} was denied because adult recreational use is not allowed in area ${metadata.id}`
      )
      return true
    }
  }
  return false
}

/**
 * Check if restricted to residential addresses only.
 * Only deny strictly commercial addresses - some residential addresses can have an rdi of null or 'unknown'
 **/
export function checkResidentialDeliveryOnly(address: GeoAddress): boolean {
  for (const metadata of address.metadata) {
    if ('residential_delivery_only' in metadata.properties && metadata.properties.residential_delivery_only) {
      if ((address.rdi || 'unknown').toLowerCase() === 'commercial') {
        console.info(
          `Setting address to ${address.id} was denied because it is a commercial address in a residential only delivery area ${metadata.id}`
        )
        return true
      }
    }
  }
  return false
}

/**
 * Check if address is in a restricted delivery zone
 */
export function checkRestrictedDeliveryZone(address: GeoAddress): boolean {
  for (const metadata of address.metadata) {
    if ('rdz' in metadata.properties && metadata.properties.rdz) {
      console.info(
        `Setting address to ${address.id} was denied because it is in a restricted delivery zone ${metadata.id}`
      )
      return true
    }
  }
  return false
}
