import { DeepPartial } from 'redux'

import { ActiveLocation } from '@/redux/location/reducer'
import { AVAILABLE_MENUS } from '@helpers/constants'

export function locationHasMenu(address: DeepPartial<ActiveLocation>): boolean {
  if (address && address.menus) {
    return address.menus.some((item) => AVAILABLE_MENUS.includes(item))
  }
  return false
}
