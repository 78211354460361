import styled from '@emotion/styled'

import { breakpoint } from '@/theme'

type Suggestion = {
  label?: string
  icon?: string
  title?: string
  body?: string
  description?: string
  business?: boolean
  street?: string
  city?: string
  state?: string
  zip?: string
}

export function renderSuggestion(suggestion: Suggestion = {}) {
  suggestion = formatSuggestion(suggestion)

  return (
    <Suggestion id={suggestion.label}>
      <SuggestionIcon src={suggestion.icon} />
      <SuggestionText>
        <SuggestionTitle>{suggestion.title}</SuggestionTitle>
        <SuggestionBody>{suggestion.body}</SuggestionBody>
      </SuggestionText>
    </Suggestion>
  )
}

export function formatSuggestion(suggestion: Suggestion) {
  if (!suggestion) return

  suggestion.title = suggestion.business ? suggestion.title : suggestion.street || suggestion.zip || suggestion.city

  suggestion.body = (
    suggestion.street
      ? [suggestion.business ? suggestion.street : null, suggestion.city, suggestion.state]
      : [suggestion.zip ? suggestion.city : null, suggestion.state]
  )
    .filter(Boolean)
    .join(', ')

  // for recent address' we save a custom 'recent.svg' icon. Let's make sure we respect it.
  if (!suggestion.icon) {
    suggestion.icon = suggestion.business
      ? '/static/location/office.svg'
      : suggestion.street
      ? '/static/icons/location.svg'
      : '/static/location/global-pin.svg'
  }

  return suggestion
}

export function getSuggestionValue(suggestion: Suggestion) {
  if (!suggestion.description) {
    return suggestion.title
  }

  // We want to remove 'United States' from the end of a suggestions description
  return suggestion.description.split(', ').slice(0, -1).join(', ')
}

const Suggestion = styled.div`
  padding: 16px 8px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoint.max.md}px) {
    padding: 0.667rem;
  }
`

const SuggestionText = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: left;
  position: relative;
  display: flex;
  white-space: nowrap;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${breakpoint.max.md}px) {
    padding-left: 0.1667rem;
  }
`

const SuggestionTitle = styled.div`
  font-size: 14px;
  display: flex;
  flex-shrink: 0;

  @media (min-width: ${breakpoint.min.md}px) {
    margin-bottom: 0.4rem;
  }

  @media (max-width: ${breakpoint.max.md}px) {
    white-space: nowrap;
  }
`

const SuggestionBody = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textDisabled};

  @media (max-width: ${breakpoint.max.md}px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const SuggestionIcon = styled.img`
  display: flex;
  flex-shrink: 0;
  height: 20px;
  width: 1.667rem;
  margin-right: 0.667rem;

  @media (min-width: ${breakpoint.min.md}px) {
    width: 1.4rem;
    margin-right: 1rem;
  }
`
