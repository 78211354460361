import { ClientSideResponse } from '@bff/@types/types'
import { GeoAddress } from '@bff/models/bff/geolocality'
import { ErrorResponse } from '@bff/models/bff/menu'
import { Origin } from '@bff/models/og/rules'
import { NEXTJS_API_PATH, WWW_BASE_URL } from '@bff/utils/constants'

export type AddressPostRequest = {
  address: string
}

export type RulesRequest = {
  latitude: number
  longitude: number
  types: string | string[]
  origin: Origin
}

export default async function postClientSideAddress({ address }: AddressPostRequest): ClientSideResponse<GeoAddress> {
  let result = null
  let statusCode: number = null
  const URL = `${WWW_BASE_URL}${NEXTJS_API_PATH}/addresses`

  if (!address) return { err: { message: 'Missing address', statusCode: 400 }, data: null }

  try {
    const body = JSON.stringify({ address })
    const response = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    result = await response.json()
    statusCode = response.status
    if (!response.ok) {
      const errorResult = result as ErrorResponse
      return { err: { message: errorResult?.message, statusCode }, data: null }
    }
    return { err: null, data: result }
  } catch (err) {
    console.error(`Geolocality responded unexpectedly when creating a new address for ${address}`, err)
    return { err: { message: result?.message, statusCode } as ErrorResponse, data: null }
  }
}

export async function getClientSideRules({ latitude, longitude, types, origin }: RulesRequest) {
  let result = null
  let statusCode: number = null
  const URL = `${WWW_BASE_URL}${NEXTJS_API_PATH}/rules`

  if (!latitude && !longitude) return { err: { message: 'Missing coordinates', statusCode: 400 }, data: null }

  const ruleType = Array.isArray(types) ? types.join(',') : types
  try {
    const queryParams = new URLSearchParams({
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      types: ruleType,
      origin: origin
    })
    const response = await fetch(`${URL}?${queryParams.toString()}`)
    result = await response.json()
    statusCode = response.status
    if (!response.ok) {
      const errorResult = result as ErrorResponse
      return { err: { message: errorResult?.message, statusCode }, data: null }
    }
    return { err: null, data: result }
  } catch (err) {
    console.error(
      `Geolocality responded unexpectedly when requesting ${origin} legacy rules ${ruleType} at ${latitude} x ${longitude}`,
      err
    )
    return { err: { message: result?.message, statusCode } as ErrorResponse, data: null }
  }
}
